import loadable from '@loadable/component';
import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import useRoute from '@core/hooks/useRoute';
import ScrollTop from '@core/utils/ScrollTop';

import classes from './style.module.scss';
import './style.scss';

const ChangelogPost = loadable(() => import('./Post'), { ssr: true });
const ChangelogList = loadable(() => import('./List'), { ssr: true });

const typeIconClasses = {
  added: `icon-plus1 ${classes.ChangelogIcon_added}`,
  fixed: `icon-check1 ${classes.ChangelogIcon_fixed}`,
  improved: `icon-heart1 ${classes.ChangelogIcon_improved}`,
  deprecated: `icon-alert-triangle ${classes.ChangelogIcon_deprecated}`,
  removed: `icon-x ${classes.ChangelogIcon_removed}`,
};

const ChangelogRoute = props => {
  const { search } = useLocation();
  const { state, loading } = useRoute(props);
  const { project } = useContext(ProjectContext);
  const {
    appearance: { changelog },
  } = project;
  const { showAuthor, showExactDate } = changelog;

  return (
    <main className="rm-Changelog" id="content">
      <div className={`rm-Container rm-ContainerNarrow ${loading ? 'rm-Changelog_loading' : ''}`}>
        <Switch>
          <Route path="/changelog/:slug">
            <ScrollTop />
            <ChangelogPost
              changelog={state?.changelog || {}}
              exactDate={showExactDate}
              showAuthor={showAuthor}
              typeIconClasses={typeIconClasses}
            />
          </Route>
          <Route exact path="/changelog">
            <ScrollTop deps={[search]} smooth />
            <ChangelogList loading={loading} typeIconClasses={typeIconClasses} {...state} />
          </Route>
        </Switch>
      </div>
    </main>
  );
};

export default ChangelogRoute;
